import React, { useState } from 'react';
import './App.css';  // Ensure Tailwind setup is applied
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';
import Login from './components/Login';
import AwardBanner from './components/AwardBanner';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { t } = useTranslation();

  if (!isAuthenticated) {
    return <Login onLogin={setIsAuthenticated} />;
  }

  return (
    <div className="font-sans bg-gray-900 text-gray-100">
      {/* Header Section with Logo */}
      <header className="bg-gray-900 text-white p-6 text-center relative">
        <LanguageSwitcher />
        <img src="logo.png" alt="Logo" className="absolute left-4 top-4 w-16" />
        <h1 className="text-xl font-bold" style={{"line-height": 46}} >{t('header.title')}</h1>
        <h2 className="text-lg mt-2">{t('header.subtitle')}</h2>
        <p className="mt-2 text-sm">{t('welcome')}</p>
      </header>
      <AwardBanner />

      {/* Why Us Section with Background Image */}
      <section
        className="bg-black py-12 px-8 text-center bg-cover bg-center bg-fixed"
        style={{ backgroundImage: "url('office.png')" }}
      >
        <div className="bg-black bg-opacity-75 p-8">
          <h2 className="text-3xl font-bold text-white">{t('whyUs.title')}</h2>
          <div className="mt-8 flex flex-col items-center md:flex-row md:justify-center space-y-4 md:space-y-0 md:space-x-8">
            <div className="text-left">
              <ul className="list-none space-y-4 text-lg">
                <li><span className="text-yellow-500">✔</span> {t('whyUs.points.0')}</li>
                <li><span className="text-yellow-500">✔</span> {t('whyUs.points.1')}</li>
                <li><span className="text-yellow-500">✔</span> {t('whyUs.points.2')}</li>
              </ul>
              <p className="mt-6 italic text-gray-400">{t('whyUs.testimonial')}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Meet Hamorn Section */}
      <section className="bg-gray-800 py-12 px-8">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <div className="md:w-1/2 text-left">
            <h2 className="text-3xl font-bold text-white">{t('meetHamorn.title')}</h2>
            <p className="mt-4 text-lg text-gray-400">{t('meetHamorn.description1')}</p>
            <p className="mt-4 text-lg text-gray-400">{t('meetHamorn.description2')}</p>
            
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img className="rounded-full shadow-lg w-48 h-48 object-cover" src="hamorn.png" alt="Hamorn" />
          </div>
        </div>
      </section>

      {/* /* Immigrate to Canada Section with Background Image */ }

      <section
        className="bg-black py-12 px-8 text-center bg-cover bg-center bg-center bg-immigration"
      >
        <div className="bg-black bg-opacity-75 p-8">
          <h2 className="text-3xl font-bold text-white">{t('immigrateToCanada.title')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            <div className="p-6 bg-gray-700 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-white">{t('immigrateToCanada.categories.temporaryEntry.title')}</h3>



              <p className="mt-2 text-gray-400">
  {t('immigrateToCanada.categories.temporaryEntry.description')
    .split('、')
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && '、'}
        <br />
      </React.Fragment>
    ))}
</p>





            </div>
            <div className="p-6 bg-gray-700 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-white">{t('immigrateToCanada.categories.economicClasses.title')}</h3>
  


              <p className="mt-2 text-gray-400">
  {t('immigrateToCanada.categories.economicClasses.description')
    .split('、')
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && '、'}
        <br />
      </React.Fragment>
    ))}
</p>



            </div>
            <div className="p-6 bg-gray-700 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-white">{t('immigrateToCanada.categories.familyClasses.title')}</h3>


        

              <p className="mt-2 text-gray-400">
  {t('immigrateToCanada.categories.familyClasses.description')
    .split('、')
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && '、'}
        <br />
      </React.Fragment>
    ))}
</p>



            </div>
            <div className="p-6 bg-gray-700 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-white">{t('immigrateToCanada.categories.refugeeProtection.title')}</h3>


    


              <p className="mt-2 text-gray-400">
  {t('immigrateToCanada.categories.refugeeProtection.description')
    .split('、')
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && '、'}
        <br />
      </React.Fragment>
    ))}
</p>



            </div>
            <div className="p-6 bg-gray-700 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-white">{t('immigrateToCanada.categories.enforcement.title')}</h3>




              <p className="mt-2 text-gray-400">
  {t('immigrateToCanada.categories.enforcement.description')
    .split('、')
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && '、'}
        <br />
      </React.Fragment>
    ))}
</p>




            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-800 text-white py-12 px-8 text-center">
        <h2 className="text-3xl font-bold mb-6">{t('contact.title')}</h2>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
          <div className="flex items-center space-x-4">
            <i className="fas fa-phone-alt"></i>
            <span>{t('contact.phone')}</span>
          </div>
          <div className="flex items-center space-x-4">
            <i className="fas fa-envelope"></i>
            <span>{t('contact.email')}</span>
          </div>
          <div className="flex items-center space-x-4">
            <i className="fas fa-globe"></i>
            <span>{t('contact.website')}</span>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-black text-gray-400 py-12">
        <div className="container mx-auto text-center">
          <p>{t('footer.copyright')}</p>
          <div className="flex justify-center mt-4 space-x-6">
            <a href={t('footer.socialMedia.facebook')} className="text-white"><i className="fab fa-facebook-f"></i></a>
            <a href={t('footer.socialMedia.instagram')} className="text-white"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </footer>


      
     {/* WhatsApp Floating Button */}
     <div className="fixed bottom-4 right-4">
        <a
          href="https://wa.me/14374437344"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 text-white p-4 rounded-full shadow-lg flex items-center justify-center"
        >
          <i className="fab fa-whatsapp text-2xl"></i>
        </a>
      </div>



    </div>
  );
}

export default App;