import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import awardBadge from '../assets/2024-badge-001.jpeg';

const AwardBanner = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div 
        className="bg-yellow-500 text-black py-3 px-4 text-center animate-pulse cursor-pointer hover:bg-yellow-400 transition-colors"
        onClick={toggleModal}
      >
        <div className="flex items-center justify-center space-x-2">
          <span className="text-2xl">🏆</span>
          <span className="font-bold">{t('award.title')}</span>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4" onClick={toggleModal}>
          <div className="relative max-w-2xl w-full" onClick={e => e.stopPropagation()}>
            <button 
              className="absolute -top-10 right-0 text-white text-xl font-bold p-2"
              onClick={toggleModal}
            >
              ✕
            </button>
            <img 
              src={awardBadge} 
              alt="Canadian Choice Award 2025 Nominee Badge" 
              className="w-full h-auto rounded-lg shadow-2xl"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AwardBanner; 