import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsMenuOpen(false); // Close the menu after selecting a language
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <button 
        onClick={toggleMenu} 
        className="fixed top-4 right-4 bg-gray-800 text-white p-2 rounded"
      >
        ☰
      </button>
      {isMenuOpen && (
        <div className="fixed top-0 right-0 h-full w-64 bg-gray-800 text-white flex flex-col items-center space-y-4 p-4">
          <button 
            onClick={() => setIsMenuOpen(false)} 
            className="self-end text-white text-2xl bg-transparent"
          >
            ✕
          </button>
          <button 
            onClick={() => changeLanguage('en')} 
            className="w-full py-2 bg-blue-500 rounded hover:bg-blue-700 transition duration-300 text-sm"
          >
            English
          </button>
          <button 
            onClick={() => changeLanguage('zh-TW')} 
            className="w-full py-2 bg-blue-500 rounded hover:bg-blue-700 transition duration-300 text-sm"
          >
            繁體中文
          </button>
          <button 
            onClick={() => changeLanguage('zh-CN')} 
            className="w-full py-2 bg-blue-500 rounded hover:bg-blue-700 transition duration-300 text-sm"
          >
            简体中文
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;